import {
	useState,
	MutableRefObject,
	useEffect,
	useCallback,
} from "react";

type Ref = MutableRefObject<HTMLDivElement|undefined>;

export const useDrag = (el:Ref) => {

	const [ dragging, setDragging ] = useState(false);

	const handleMouseUp = useCallback(() => setDragging(() => false), []);
	const handleMouseDown = useCallback(() => setDragging(() => true), []);

	useEffect(() => {
		if(!el.current){ return; }
		el.current.addEventListener("mouseup", handleMouseUp);
		el.current.addEventListener("mousedown", handleMouseDown);
		return () => {
			if(!el.current){ return; }
			el.current.removeEventListener("mouseup", handleMouseUp);
			el.current.removeEventListener("mousedown", handleMouseDown);
		};
	}, []);

	return {
		dragging,
	};
};