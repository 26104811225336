import { VideoJsPlayer } from "video.js";
import { VROptions } from "../videojs-types";
import { URLUtils } from "@utils";

enum PlayerFlags {
	Debug = "debug",
	NoMotion = "no_motion",
}

export const pano = function(this:VideoJsPlayer){

	const flags = URLUtils.extractSearchArray(location.search, "flags");

	const vrOptions:VROptions = {
		...vrDefaults,
		debug:flags.includes(PlayerFlags.Debug),
		motionControls:!Boolean(flags.includes(PlayerFlags.NoMotion)),
	};

	// enable vr rendering
	(this as any).vr(vrOptions);
};


const vrDefaults:VROptions = {
	projection: "360",
	disableTogglePlay:true,
	sphereDetail:32
};

