import { VideoJsPlayer } from "video.js";
import { QualityManager } from "../utils/QualityManager";

// delay before setting quality
const DELAY = 2000;

export const maxQuality = function(this:VideoJsPlayer){
	const qmanager = new QualityManager(this);
	setTimeout(() => {
		const maxIndex = qmanager.getMaxQualityIndex();
		if(maxIndex < 0){
			console.log("Error setting max quality: no sources founded");
			return;
		}
		qmanager.setQualityLevelIndex(maxIndex);
	}, DELAY);
};