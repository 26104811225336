import { FC } from "react";
import { useLocation } from "react-router";
import { Video360 } from "@360/video";
import { URLUtils } from "@utils";
import { Box } from "@ui";
import { ErrorScreen } from "@error";

const Page:FC = () => {

	const l = useLocation();

	const sources = extractSourcesFromSearch(l.search);

	if(sources.length === 0){
		return <ErrorScreen/>;
	}

	const flags = URLUtils.extractSearchArray(l.search, "flags");

	return (
		<Box.Abs>
			<Video360
			sources={ sources }
			flags={ flags }
			/>
		</Box.Abs>
	);
};

export default Page;


const decodePayload = (v:string):Record<string,any> => {
	try {
		return JSON.parse(Buffer.from(decodeURIComponent(v), "base64").toString());
	}
	catch{}
	return {};
}

const extractSourcesFromSearch = (search:string):string[] => {
	const p = new URLSearchParams(search);

	const urlsrc = p.getAll("src");

	if(urlsrc.length > 0){
		return urlsrc;
	}
	const qp = decodePayload(p.get("q") || "");
	const qarr:any[] = qp && Array.isArray(qp["src"]) ? qp["src"] : [];
	return qarr.filter(v => typeof(v) === "string") as string[];
}