
export const getPathMime = (src:string) => {
	const u = new URL(src);
	const fn = u.pathname.split("/").pop() || "";
	const [ _, ext ] = fn.split(".");
	return mimes[ext || ""] || "";
};

const mimes:Record<string,string|undefined> = {
	"mp4":"video/mp4",
	"webm":"video/webm",
	"mkv":"video/mkv",
	"m3u8":"application/x-mpegURL",
	"mpd":"application/dash+xml"
};