import { VideoJsPlayer } from "video.js";
import { QualityLevel } from "videojs-contrib-quality-levels";
import { QualityManager } from "../utils/QualityManager";

export const deviceQuality = function(this:VideoJsPlayer){
	const qmanager = new QualityManager(this);
	if(!this.hasPlugin("qualityLevels")){
		this.qualityLevels();
	}
	const handleAddQualityLevel = (ql:QualityLevel) => {
		qmanager.filterQualityByDevice(ql);
		qmanager.setQualityLevelIndex(0);
	};
	this.qualityLevels().on("addqualitylevel", e => handleAddQualityLevel(e.qualityLevel));
};