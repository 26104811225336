import { FC, useEffect, useRef } from "react";
import videojs, { VideoJsPlayerOptions, VideoJsPlayer } from "video.js";
import styled from "styled-components";
import { VHSOptions } from "./videojs-types";
import { Box } from "@ui";
import { useDrag } from "./hooks/useDrag";
import * as Utils from "./utils/mime";

export const Video360:FC<{
	sources?:string[];
	flags?:string[];
}> = (props) => {

	const dragRef = useRef<HTMLDivElement>();
	const videoRef = useRef<HTMLVideoElement|null>(null);
	const playerRef = useRef<VideoJsPlayer|null>(null);

	const { dragging } = useDrag(dragRef);

	const vsources = (props.sources || [])
	.map(src => ({
		src,
		type:Utils.getPathMime(src),
	}));

	useEffect(() => {
		if(playerRef.current || !videoRef.current){ return; }
		const options = getPlayerOptions({
			flags:props.flags || [],
			sources:vsources,
		});
		playerRef.current = videojs(videoRef.current, options);
	}, [ videoRef ]);

	useEffect(() => {
		const player = playerRef.current;
		return () => {
			if (player) {
				player.dispose();
				playerRef.current = null;
			}
		};
	}, [ playerRef ]);


	const rootCls = `${ dragging ? "active" : "" }`;

	return (
		<DragBox className={ rootCls }>
			<VideoContainer
			data-vjs-player
			ref={ dragRef as any }
			>
				<video
				playsInline
				crossOrigin="anonymous"
				ref={videoRef}
				className="video-js"
				/>
			</VideoContainer>
		</DragBox>
	);
};

const DragBox = styled(Box.Abs)`
	overflow:hidden;
	&:not(.active){ cursor:grab; }
	&.active { cursor:grabbing; }
`;

const VideoContainer = styled(Box.Abs)`
	overflow:hidden;
`;

enum PlayerFlags {
	Controls = "controls",
	UseNative = "use_native",
	Debug = "debug",
}

type SetupConfig = {
	sources:{
		type:string;
		src:string;
	}[];
	flags:string[];
};

const getPlayerOptions = (options:SetupConfig):VideoJsPlayerOptions => {

	const {
		sources,
		flags,
	} = options;

	const useNative = flags.includes(PlayerFlags.UseNative) || videojs.browser.IS_SAFARI;
	const controls = flags.includes(PlayerFlags.Controls);

	const vhs:VHSOptions = {
		overrideNative: !useNative,
		bandwidth:50 * 1000000,
		experimentalBufferBasedABR:true,
		useDevicePixelRatio:true,
		limitRenditionByPlayerDimensions:false,
		useBandwidthFromLocalStorage:true,
		handlePartialData:true,
		useNetworkInformationApi:true,
		maxPlaylistRetries:10,
	};

	return {
		controls,
		sources,
		inactivityTimeout:0,
		html5:{
			vhs,
			disablePictureInPicture:true,
			experimentalBufferBasedABR:true,
		},
		bigPlayButton:false,
		controlBar:{
			pictureInPictureToggle:false,
			subsCapsButton:false
		},
		muted:true,
		autoplay:true,
		fill:true,
		loop:true,
		preload:"metadata",
	};
};