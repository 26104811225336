import "video.js/dist/video-js.css";
import "video.js";
import "videojs-contrib-quality-levels";
import "videojs-vr";
import videojs from "video.js";
import * as Plugins from "./plugins";

enum PluginName {
	DeviceQuality = "deviceQuality",
	MaxQuality = "maxQuality",
	Panorama = "pano",
}

const pluginMap:Record<string,any> = {
	[PluginName.Panorama]:Plugins.pano,
	[PluginName.MaxQuality]:Plugins.maxQuality,
	[PluginName.DeviceQuality]:Plugins.deviceQuality,
};

// register plugins
Object.keys(pluginMap).forEach(k => videojs.registerPlugin(k, pluginMap[k]));

// init plugins for new players
videojs.hook("setup", player => {
	Object.keys(pluginMap).forEach(k => (player as any)[k]());
});

export * from "./Video360";