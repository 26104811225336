import { FC } from "react";
import styled, { css } from "styled-components";
import { Icon } from "@ui";

export const ErrorScreen:FC<{
	error?:string;
}> = (props) => {
	return (
		<ErrorBox>
			<ErrorTitle>
				<Icon name="ufo"/>
				{/* { props.error } */}
			</ErrorTitle>
		</ErrorBox>
	);
};

const BGGradient = css`
	background: rgb(61,54,88);
	background: linear-gradient(0deg, rgba(61,54,88,1) 0%, rgba(35,91,157,1) 100%);
	/* background-image: linear-gradient(to top, #434343 0%, black 100%); */
`;

const ErrorBox = styled.div`
	width:100%;
	height:100%;
	${BGGradient};
	display:flex;
`;

const ErrorTitle = styled.h1`
	margin:auto;
	color:white;
	font-size: 5em;
	/* text-shadow: 2px 2px #000000; */
	/* font-weight: bolder; */
	/* letter-spacing: 0.2em; */
`;