import { FC } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { RouterView } from "@routing";
import { routes } from "./routes";
import { Box } from "@ui";

export const App:FC = () => {
	return (
		<Box.Abs>
			<Router>
				<RouterView routes={routes}/>
			</Router>
		</Box.Abs>
	);
};