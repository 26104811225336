import { VideoJsPlayer } from "video.js";
import { QualityLevel } from "videojs-contrib-quality-levels";

export class QualityManager {

	public setQualityLevelIndex (i:number) {
		const levels = this._player.qualityLevels();
		if(i === levels.selectedIndex){ return; }
		(levels as any).selectedIndex_ = i;
		levels.trigger({ type: "change", selectedIndex: i } as any);
	};

	public getQualityList() {
		return this._player.qualityLevels();
	}

	public getMaxQualityIndex() {
		const levels = this.getQualityList();
		if(levels.length === 0){ return -1; }
		let mi = 0;
		let mv = levels[0].width || 1;
		for(let i = 0; i < levels.length; i++){
			if(mv < (levels[i].width || 1)){
				mv = levels[i].width || 1;
				mi = i;
			}
		}
		return mi;
	};

	public filterQualityByDevice(ql:QualityLevel){
		const i = qfilters.findIndex(v => !v(ql));
		if(i < 0){ return; }
		ql.enabled = false;
		this._player.qualityLevels().removeQualityLevel(ql as any)
	}

	constructor(player:VideoJsPlayer){
		this._player = player;
	}

	private _player:VideoJsPlayer;

}

type QualityFilter = (ql:QualityLevel) => boolean;

const isMobile = () => /Mobi|Android/i.test(navigator.userAgent);
const isFirefox = () => window.navigator.userAgent.includes("Firefox");

const exceedsResolution = (ql:QualityLevel, w:number, h:number) => {
	return (ql.width || 1) > w || (ql.height || 1) > h;
};

// Some platforms have issues with higher resolutions on certain codecs
const qfilters:QualityFilter[] = [
	ql => isFirefox() ? !exceedsResolution(ql, 4096, 4096) : true,
	ql => isMobile() ? !exceedsResolution(ql, 4096, 4096) : true,
];