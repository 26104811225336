import { FC } from "react";
import { Route, Switch, Redirect } from "react-router-dom";

export const RouterView: FC<{
	routes:{
		path:string;
		component:any;
	}[];

}> = props => {
	const { routes } = props;

	const droutes = routes.map(r => {
		return (
			<Route exact path={r.path} key={`route-${r.path}`}>
				<r.component/>
			</Route>
		);
	});

	return (
		<Switch>
			{ droutes }
			<Redirect to="/" />
		</Switch>
	);
};