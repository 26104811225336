



export class URLUtils {

	public static extractSearchArray = (search:string, key:string) => {
		const p = new URLSearchParams(search);
		return (p.get(key) || "")
		.split(",")
	}

	public static pathIsImage = (path:string) => {
		const [ ext ] = (new URL(path)).pathname.split(".");
		return mediaFormats.img.includes(ext);
	};

	public static pathIsVideo = (path:string) => {
		const [ ext ] = (new URL(path)).pathname.split(".");
		return mediaFormats.vid.includes(ext);
	};


}


const mediaFormats = {
	img:[ "jpg", "jpeg", "png", "gif", "svg", "webp", "apng", "avif" ],
	vid:[ "mp4", "mkv", "webm", "ts", "mpd", "m3u8" ],
}